/* Countdown.css */
.time-box {
    display: inline-block;
    padding: 20px;
    margin: 0 5px;
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  
  .label {
    padding: 10px;
  }

  .time-box p {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
    text-align: center;

  }
  
  .flip-enter {
    animation: flipInY 0.5s both;
  }
  
  .flip-exit {
    animation: flipOutY 0.5s both;
  }
  
  @keyframes flipInY {
    from {
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      animation-timing-function: ease-in;
      opacity: 0;
    }
  
    to {
      transform: perspective(400px) rotate3d(0, 0, 0, 0);
      animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  
  @keyframes flipOutY {
    from {
      transform: perspective(400px) rotate3d(0, 0, 0, 0);
      animation-timing-function: ease-in;
    }
  
    to {
      transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
      animation-timing-function: ease-out;
      opacity: 0;
    }
  }
  

@keyframes colorfulFlash {
  0% { color: red; }
  25% { color: rgb(89, 255, 0); }
  50% { color: green; }
  75% { color: yellow; }
  100% { color: red; }
}
